export const BadalAttributes = [
  {
    icon: 'R',
    title: 'Nimble',
    description: 'We don’t like wasting time. Our un-siloed approach lets us solve problems quicker and more effectively.'
  },
  {
    icon: 'S',
    title: 'Expert',
    description: 'We believe that to be the best, you need to work with the best. As experts, we push each other every day to do better for our clients.'
  },
  {
    icon: 'T',
    title: 'Straightforward',
    description: 'The Cloud can be complicated. That’s why we try to be as direct as possible. We give it to you straight, so you can feel confident at every step.'
  }
]