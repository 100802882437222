export const Testimonials = [
  {
    name: 'Pavel Choulguine',
    title: 'CTO',
    image: 'pavel-choulguine',
    testimonial: `Badal is a Google Partner that every startup wishes they had. They were able to guide us through the most complicated of transitions with no downtime.`
  },
  {
    name: 'Ambles Kwok',
    title: 'CTO',
    image: 'ambles-kwok',
    testimonial: `Badal helped us establish a DevOps practice by introducing containerization and GKE to our workflow. Allowed the dev team to focus on mission at hand will ensuring a secure and resilient infrastructure in the backend.`
  }
]