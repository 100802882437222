<template>
  <div id="company-event" class="page-view">
    <div class="main-content-container">
      <div class="container">
        <div class="hero">
          <h1 class="pink">Thank You For Your Submission</h1>
          <h1>Your Name Has Been Added To The List</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.uk-sticky .uk-sticky-fixed {
  display: none !important;
}
</style>

<script>
export default {
  metaInfo() {
    return {
      title: "Thank You",
      meta: [
        { name: "description", content: `${this.titleStatement}` },
        { name: "robots", content: "index, follow" },
        { property: "og:type", content: "article" },
        { property: "og:title", content: `${this.pageTitle}` },
        { property: "og:description", content: `${this.titleStatement}` },
        { property: "og:url", content: `${this.$route.path}` },
        { property: "og:site_name", content: "BADAL" },
        { property: "twitter:title", content: `${this.pageTitle}` },
        { property: "twitter:description", content: `${this.titleStatement}` },
        { property: "twitter:site", content: "BADAL" },
      ],
    };
  },
  components: {},
  name: "ThankYou",
  data() {
    return {
      pageTitle: "Badal Events",
      titleStatement: "We are your Cloud experts.",
      cloud6Processes: [
        {
          title: "Plan",
          icon: "A",
        },
        {
          title: "Deploy",
          icon: "B",
        },
        {
          title: "Automate",
          icon: "C",
        },
        {
          title: "Secure",
          icon: "D",
        },
        {
          title: "Manage",
          icon: "E",
        },
        {
          title: "Enterprise",
          icon: "F",
        },
      ],
    };
  },
};
</script>
