<template>
  <div id="cloudsolutions-page" class="page-view">
    <page-hero
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement"
      :hideButton="true"
      :hideBreadCrumbs="true"
      :hideHeroImage="true"
    >
    </page-hero>

    <div class="cloud6-process">
      <div class="uk-container">
        <div class="cloud-process-inner">
          <div class="main-content-left">
            <h2>Our Cloud6 Process</h2>
            <p>Created by our experienced team of architects, engineers and developers, Badal’s Cloud 6 Process is designed to help your enterprise flourish now and well into the future.</p>
          </div>

          <div class="main-content-right">
            <div class="processes">
              <div class="content-block" v-for="service in services" :key="service.title">
                <div class="content-block-inner">
                  <div class="icon">{{ service.icon }}</div>
                  <div class="content">
                    <div class="content-inner">
                      <h3>{{ service.title }}</h3>
                      <p>{{ service.description }}</p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="solutions">
      <div class="uk-container">
        <div class="content">
          <h2>SOLUTIONS TO FIT YOUR NEEDS</h2>
          <p>Learn the specifics of how our experts can modernize your processes and help you unlock more value from your cloud architecture.</p>
        </div>

        <div class="solutions-content">
          <slider :items="solutions" 
            :responsive="numberOfSlides"
            :centered="false"
            identifier="solutions" 
            colorScheme="secondary">

            <li class="solution" v-for="solution of solutions" :key="solution.title">
              <div :class="['solution-card position-one', 'delay']" >
                <div class="content-inner">
                  <div class="header-container">
                    <h3>{{ solution.title }}</h3>
                  </div>

                  <div class="summary">
                    {{ solution.summary }}
                  </div>

                  <div class="how-it-works">
                    How it works
                  </div>
                </div>
              </div>
              <div :class="['solution-card position-two']">
                <div class="content-inner">
                  <div class="content" v-html="solution.details"></div>
                </div>

                <div class='close'>x</div>
              </div>
            </li>
          </slider>
        </div>  
      </div>
    </div>

    <rounded-bottom-two></rounded-bottom-two>

    <div class="success-stories">
      <div class="uk-container">
        <div class="main-content">
          <div class="main-content-left">
            <div id="cameco-logo"></div>
          </div>

          <div class="main-content-right">
            <h2>Case Study</h2>
            <h2 class="large-title">Cloud Foundations</h2>
            <p>
              A transformational approach to adopting an agile, flexible and secure organizational cloud practice.
            </p>
            <primary-button
              route="/casestudy"
              text="View more">
            </primary-button>
          </div>
        </div>
      </div>
    </div>

    <div class="icon-strip uk-text-center">
      <div class="uk-container-expand">
        <ul class="uk-list">
          <li class="" v-for="icon in iconStrip" :key="icon.title">
            <div class="icon-wrap">
              <img :src="require('@' + '/assets/svgs/icon-strip/' + icon.logo +'.svg')"/>
            </div>
            <div class="icon-title">{{ icon.title }}</div>
          </li>
        </ul>
      </div>
    </div>

    <brand-slider></brand-slider>

    <div class="contact-section uk-text-center">
      <div class="uk-container">
        <div class="solutions-inner">
          <primary-button
            route="/contact"
            text="Contact us">
          </primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import { IconStrip } from '@/content/Services.js'
import { CloudSolutions, Services } from '@/content/Services.js'
import BrandSlider from '@/layouts/BrandSlider.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import RoundedBottomTwo from '@/components/RoundedBottomTwo.vue'
import Slider from '@/components/Slider.vue'

export default {
  metaInfo() {
    return {
      title: 'Cloud Solutions',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    BrandSlider,
    PrimaryButton,
    RoundedBottomTwo,
    Slider
  },
  name: 'CloudSolutions',
  data () {
    return {
      pageTitle: 'Infrastructure Modernization',
      titleStatement: 'Embracing a cloud-native approach means not only cloud migration of your on-premise data centre but evolving your entire technology stack and internal DevOps to take advantage of the power, scalability, flexibility and cost savings of cloud platforms through your cloud usage.',
      iconStrip: [],
      solutions: [],
      services: [],
      numberOfSlides: {
        small: 1,
        medium: 3,
        large: 3,
        xlarge: 4
      },
    }
  },
  mounted () {
    this.setSolutionCardAnimation()
  },
  created () {
    this.solutions = CloudSolutions
    this.services = Services
    this.iconStrip = IconStrip
  },
  methods: {
    setSolutionCardAnimation () {
      const positionOneDivs = document.getElementsByClassName("position-one");
      const positionTwoDivs = document.getElementsByClassName("position-two");
      
      for(let i = 0; i < positionOneDivs.length; i++) {
        positionOneDivs[i].onclick = () => {
          positionOneDivs[i].classList.remove('delay')
          positionOneDivs[i].classList.add('active')
          positionTwoDivs[i].classList.add('active')
          positionTwoDivs[i].classList.add('delay')
        }
      }

      for(let i = 0; i < positionTwoDivs.length; i++) {
        positionTwoDivs[i].onclick = () => {
          positionTwoDivs[i].classList.remove('active')
          positionTwoDivs[i].classList.remove('delay')
          positionOneDivs[i].classList.remove('active')
          positionOneDivs[i].classList.add('delay')
        }
      }
    }
  }
}
</script>