export const Stories = [
  {
    name: 'Loblaws Digital',
    testimonial: `Badal built a reporting platform that is intuitive, and easy to use without
    sacrificing the granular level of data control needed by a data-driven
    organization like Loblaws. Badal developed LookML data models and
    views that standardized an iceberg of computations and business logic
    utilized across various business units into a single data exploration
    experience.
    `,
    link: `https://cloudcrm.googleusercontent.com/v2/files/ALDzILp2zt8fGtJ9p5txuHyEiGX7QSHT-n-_siDt21YhEj9likaItGafkb1bbz0rQjFQvySb0RvtuWxFriSifi0l9JOEqFUrZ1EJFtg`,
    logo:"LD Logo",
    title:"Data Driven Experience for Marketing Analytics"
  },
  {
    name: 'Eyereturn',
    testimonial: `The GCP Airflow Framework was used to create a standardized Data
    Warehouse in BigQuery. Looker was used to combine and standardize
    the data from the large set of ingested data sources using derived
    tables. Dynamic KPIs and business logic calculations were then built
    on top of these tables with LookML and used to create a model where
    data could be easily explored with a user friendly interface.`,
    link:`https://cloudcrm.googleusercontent.com/v2/files/ALDzILp6G7W-aCm5DoicAI8NpFixfL0Ptr2-LWFw0K5ab6lztJ12cZdRcL3hik6vGWHsFhIlpbBIhJD53biQXEBRq0dym-ayQDSYfzo`,
    logo:"Eyereturn Marketing Logo",
    title: "Transforming Digital Marketing Analytics With Looker"
  },
  {
    name: 'Quickplay',
    testimonial: `Badal worked with Quickplay to configure a Looker instance and
    integrate with customer datasets in BigQuery. By planning and
    development of LookML, data models were created to provide
    business users with standardized, ready-to-use data needed to
    answer their questions. The LookML model can be easily leveraged to
    provide deep and vertically-specific analytics for Over the Top
    streaming space.`,
    link:`https://cloudcrm.googleusercontent.com/v2/files/ALDzILrzMoPAXtD9-HqsTY-HOKP-RbFuncurzSWOHO7KYtFBWVihQwcas0SU1F0KOTnXXX-2qdU9wah-8hT8HWafyrGCmakNbfebpIE`,
    logo:"firstlight-media",
    title:"Streaming Analytics Dashboards with Looker"
  },
  {
    name: 'Loblaws Digital',
    testimonial: `Badal's solution employed the use of Data Build Tool (DBT) and
    Looker to run consistent data quality checks across Loblaw Digital’s
    applications and platforms. The results were visualized on a
    customized and easy to use dashboard with an alerting system to
    quickly identify data quality issues and notify key stakeholders for
    protective measures.
    `,
    link:`https://cloudcrm.googleusercontent.com/v2/files/ALDzILpJIMmios-J8lsJw373FRPKnUGKk0v7Qq6qAOgLa7-Insqh9tAm6-5iP0RQM_sLSvxs5j0julioTnGA0UFXh6WT27bvIQes-4E`,
    logo:"LD Logo",
    title:"Data Quality Framework at Loblaw Digital"
  },
  {
    name: 'Loblaws Digital',
    testimonial: `The solution employed BigQuery ML Time Series (ARIMA Plus) to
    forecast expected values and automatically detect anomalies in
    customer data while accounting for trends, holidays, and seasonality.
    Intuitive Looker dashboards to provide deeper data insights, and alert
    management capabilities for reducing alert fatigue.`,
    link:`https://cloudcrm.googleusercontent.com/v2/files/ALDzILoGO89eDhbp_AuALl069PAFxHiOluP9NNgw7n6q66A85B1z-fv2eg8GPm1lIbB9jC--DlSTfyPzr07IH7nHtHj6zGBHa5IvjIY`,
    logo:"LD Logo",
    title: "Loblaw Digital's Anomaly Detection Framework"
  },
  {
    name: 'Loblaws Digital',
    testimonial: `DLP is a managed service which scans and matches data to provide
    PII types. DLP scans data daily and store results into an output
    BigQuery Table. Using the GitOps flow to configure DLP jobs, Privacy
    and Data Analyst teams can change data classification rules in a well
    governed way. Looker dashboards help view DLP historical results for
    each table and column showing deltas on recent scans.`,
    link:`https://cloudcrm.googleusercontent.com/v2/files/ALDzILpuIVLT6iuklmhnTQxFVlhIBiL2hQ0tEpK7WVnh4JacxwIIPFaPhMKuX920Lb-V3FJ8WbzwWUpK9xaMMQrK1b7r8LSx0CRBjGo`,
    logo:"LD Logo",
    title:"Protecting PII using Data Loss Prevention"
  },
]