<template>
  <div class="hero-container">
      <div class="uk-container">
        <div class="container-inner">

          <div id="breadcrumbs-container" v-if="!hideBreadCrumbs">
            <div v-if="isSingle"
              class="breadcrumbs" 
              :style="{ width: breadCrumbsHeight + 'px'}">
              <span class="active">{{ $route.path.split('/')[2] }}</span>
              <div class="divider-line"></div>
              <router-link :to="`/${$route.path.split('/')[1]}`" class="active-link" :title="`Navigate to the careers page`">{{ $route.path.split('/')[1] }}</router-link>
              <div class="divider-line"></div>
              <router-link to="/" class="home-link" :title="`Navigate to the home page`">Home</router-link>
            </div>

            <div v-else 
              class="breadcrumbs" 
              :style="{ width: breadCrumbsHeight + 'px'}">
              <span class="active">{{ $route.name }}</span>
              <div class="divider-line"></div>
              <router-link to="/" class="home-link" :title="`Navigate to the home page`">Home</router-link>
            </div>
          </div>
          
          <div :class="['call-to-action']">
            <div class="call-to-action-inner">
              <h1 class="page-title">
                {{ pageTitle }}
              </h1>

              <div class="title-statement">
                {{ titleStatement }}
              </div>

              <primary-button v-if="isCareersRoute()"
                @click="OpenPositions()"
                text="View open positions" >
              </primary-button>

               <primary-button v-else
                @click="OpenContact()"
                text="Get a proposal" >
              </primary-button>
            </div>
              
          </div>

          <div :class="['image-container']" v-if="heroImage">
              <slot name="image">
                <img :src="require('@' + '/assets/page-heroes/' + heroImage)" :alt="$route.name + ' Page Header Image'" :title="pageTitle" />
              </slot>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/PrimaryButton.vue'

import { useRoute } from 'vue-router';

export default {
  name: 'PageHero',
  components: {
    PrimaryButton
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Title'
    },
    titleStatement: {
      type: String,
      default: 'Title Statement'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    hideBreadCrumbs: {
      type: Boolean,
      default: false
    },
    isSingle: {
      type: Boolean,
      default: false
    },
    hideHeroImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      breadCrumbsHeight: '',
      heroImage: ''
    }
  },
  mounted () {
    if (!this.hideHeroImage) {
      this.heroImage = `${this.$route.name.toLowerCase().replace('single', 's')}-page-hero.png`
    }

    // Dynamically set the width of the breadcrumbs nav
    if(!this.hideBreadCrumbs) {
      const element = document.getElementById('breadcrumbs-container');
      this.breadCrumbsHeight = element.offsetHeight
    }
  },
  methods:{
    OpenPositions(){
      window.open('https://jobs.lever.co/badal','_blank');
    },
    OpenContact(){
      this.$router.push('/contact');
    },
    isCareersRoute(){
      const route = useRoute();

      return route.name === 'Careers';
    }
  }
}
</script>