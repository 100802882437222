<template>
  <router-link class="primary-btn" :to="route">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      default: ''
    },
    route: {
      type: String,
      default: ''
    }
  }
}
</script>