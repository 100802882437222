<template>
  <div id="blogs-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement">
    </page-hero>

    <div class="blog-filter-container" v-if="blogCategories.length">
      <div class="uk-container">
        <ul class="filter-list">
          <li class="filter-item active"  
            @click="setBlogFilter('all-categories')">
            All Categories
          </li>

          <li class="filter-item" 
            v-for="category of blogCategories" 
            :key="category" 
            @click="setBlogFilter(category)">
            {{ category }}
          </li>
        </ul>
      </div>
    </div>

    <div class="blogs-container">
      <div class="uk-container">
        <ul class="blogs">
          <li class="blog"
            v-for="(blog, index) of currentPageBlogs" 
            :key="blog.title" 
            :data-page="(index + 1)">

            <div class="image-container">
              <img 
                :src="blog.thumbnail" 
                :alt="blog.title" 
                :title="blog.title" 
              />
            </div>

            <div class="content-container">
              <div class="container-inner">
                <div class="blog-title">{{ blog.title }}</div>

                <div class="blog-details">
                  <div class="blog-pub-date">
                    {{formatDate( blog.pubDate) }}
                  </div>

                  <div class="blog-categories-container" v-if="blogCategories.length">
                    <ul class="blog-categories">
                      <li class="blog-category" 
                        v-for="category of blog.categories" :key="category">
                        {{ category }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="blog-link-container">
                  <a :href="blog.link" target="_blank">Full Article</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <ul class="uk-pagination uk-flex-center" uk-margin>
      <li 
        class="pagination-control"
        v-if="this.currentPageNumber !== 0">
        <a href="#" @click="getPrevPage()">
          <span uk-pagination-previous></span>
        </a>
      </li>

      <li 
        :class="[(currentPageNumber == (page - 1)) ? 'active' : '']"
        v-for="page of numberOfPages" 
        :key="page">
        <a href="#" @click="getPage((page - 1))">
          {{ page }}
        </a>
      </li>

      <li 
        class="pagination-control" 
        v-if="(this.currentPageBlogs.length >= this.blogsPerPage)">
        <a href="#" @click="getNextPage()">
          <span uk-pagination-next></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue';
import moment from 'moment';


export default {
  metaInfo() {
    return {
      title: 'Blogs',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero 
  },
  name: 'Blogs',
  data () {
    return {
      pageTitle: 'The Cloud Forecast',
      titleStatement: 'Here’s a small sample of how we’ve helped our clients streamline their organizations, and take their businesses to the next level.',
      axios: require('axios'),
      currentPageBlogs: [],
      currentPageNumber: 0,
      numberOfPages: 0,
      blogsPerPage: 4,
      blogs: [],
      blogsInitial: [],
      blogCategories: [],
      filterListItems: []
    }
  },
  async created () {
    await this.axios.get(this.$blogUrl)
      .then((response) => {
        this.blogsInitial = response.data.items
        this.setBlogs(response.data.items)  
        this.setBlogCategories()
      })
    
    if(this.$route.query.page) {
      this.getPage((this.$route.query.page - 1), true)
    }

    this.filterListItems = document.getElementsByClassName('filter-item')
    
    for(let i = 0; i < this.filterListItems.length; i++) {
      this.filterListItems[i].addEventListener('click', () => {
        this.setActiveFilter(this.filterListItems[i])
      })
    }
  },
  methods: {
    /**
     * Sets the blogs array
     * Sets the current blog page set
     * Sets the number of pages
     */
    setBlogs (blogs) {
      this.blogs = blogs
      this.currentPageBlogs = this.blogs.slice(0, 4)
      this.numberOfPages = Math.round((this.blogs.length / this.blogsPerPage))
    },
    /**
     * Sets the blogCategories (used for filters)
     */
    setBlogCategories () {
      this.blogs.forEach((item) => {
        this.blogCategories = this.blogCategories.concat(item.categories)
      })

      this.blogCategories = this.blogCategories.filter((v, i,a) => a.indexOf(v) === i)
    },
    /**
     * Applies blog category filter
     */
    setBlogFilter (category) {
      if(category === 'all-categories') {
        this.setBlogs(this.blogsInitial)
      } else {
        const blogsFiltered = this.blogsInitial.filter((el) => {
          return el.categories.includes(category)
        })

        this.setBlogs(blogsFiltered)
      }
    },
    /**
     * Determines the page we're on and sets the currentPageBlogs set
     */
    setPaginatedBlogs () {
      const startIndex = (this.currentPageNumber === 0) ? 0 : (this.currentPageNumber * this.blogsPerPage)
      const endIndex = (startIndex + this.blogsPerPage)

      this.currentPageBlogs = this.blogs.slice(startIndex, endIndex)
    },
    /**
     * Set next page for the blog posts
     */
    getNextPage () {
      if(this.currentPageBlogs.length >= this.blogsPerPage) {
        this.currentPageNumber++;
        this.setPageRoute()
        this.setPaginatedBlogs()
      }
      
    },
    /**
     * Set prev page for the blog posts
     */
    getPrevPage () {
      if(this.currentPageNumber > 0) {
        this.currentPageNumber--;
        this.setPageRoute()
        this.setPaginatedBlogs()
      }
    },
    /**
     * Set a page for the blog posts
     */
    getPage (pageIndex, ignoreRouteChange) {
      this.currentPageNumber = pageIndex

      if(!ignoreRouteChange) {
        this.setPageRoute()
      }
      
      this.setPaginatedBlogs()
    },
    /**
     * Changes the route based on selected page
     */
    setPageRoute () {
      this.$router.push({ 
        path: '/blogs', 
        query: { 
          page: (this.currentPageNumber + 1) 
        }
      })
    },
    /**
     * Sets the active blog filter
     */
    setActiveFilter (element) {
      for(let i = 0; i < this.filterListItems.length; i++) {
        this.filterListItems[i].classList.remove('active')
      }

      element.classList.add('active')
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    }
  }
}
</script>