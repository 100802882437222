<template>
  <div id="about-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement"
      :hideButton="true"
      :hideHeroImage="false"
    >
    </page-hero>

    <div class="main-content-container">
      <div class="uk-container">
        <div class="container-inner">
          <div class="main-content-left">
            <div class="main-content-title-container">
              <h2>The Cloud should be simple and effective.</h2>
              
              <p>That’s the fundamental idea that drove us to create Badal. Working in the industry, we saw too many organizations wasting time, money and resources on bloated, inefficient systems.</p>

              <p>When it comes to your needs, we cut straight to the chase. Our nimble team of world-class engineers, architects and developers are here to provide real value to enterprises looking to get the most out of their Cloud.</p>

              <p>Founded in 2018 and based out of Toronto, we’ve been serving a growing list of clients for the past three years. We look forward to showing you all the ways we can help you find business success through leaner, better technology solutions.</p>
            </div>
          </div>

          <div class="main-content-right">
            <div class="content-block" 
              v-for="attribute of badalAttributes" :key="attribute.title">
              <div class="icon">{{ attribute.icon }}</div>
              <div class="content">
                <div class="content-inner">
                  <h3>{{ attribute.title }}</h3>
                  <p>{{ attribute.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="swirl-container"></div>

    <!-- <bios></bios> -->

    <div class="make-your-business">
      <div class="image-container"></div>

      <div class="uk-container">
        <div class="flexbox content-wrapper">
          <div class="content-container">
            <h2 class="large-title">
              Give your business the Badal advantage
            </h2>

            <div class="title-statement">
              Discover how our 6Cloud approach can help your organization succeed in the modern business landscape.
            </div>

            <primary-button 
              route="/contact" 
              text="Get a proposal">
            </primary-button>
          </div>
        </div>
      </div>
    </div>

    <rounded-bottom-one></rounded-bottom-one>

    <brand-slider></brand-slider>

    <partner-slider></partner-slider>

    <testimonial-slider></testimonial-slider>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
// import Bios from '@/layouts/Bios.vue'
import BrandSlider from '@/layouts/BrandSlider.vue'
import PartnerSlider from '@/layouts/PartnerSlider.vue'
import TestimonialSlider from '@/layouts/TestimonialSlider.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import { BadalAttributes } from '@/content/About.js'
import RoundedBottomOne from '@/components/RoundedBottomOne.vue'

export default {
  metaInfo() {
    return {
      title: 'About Us',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    // Bios,
    BrandSlider,
    PartnerSlider,
    TestimonialSlider,
    PrimaryButton,
    RoundedBottomOne
  },
  name: 'About',
  data () {
    return {
      pageTitle: 'Who is Badal?',
      titleStatement: 'A brief intro to what we believe in.',
      badalAttributes: []
    }
  },
  created () {
    this.badalAttributes = BadalAttributes
  }
}
</script>