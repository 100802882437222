<template>
  <div class="brand-slider con-p">
    <div class="uk-container">
      <div class="content">
        <h2>Brands We've Helped</h2>
        <p>We’re committed to helping businesses achieve greater success. Our ever-growing list of clients is a testament to that dedication.</p>
      </div>
    </div>

    <slider :items="brands" 
      identifier="brands"
      :autoplay="true"
      :pauseOnHover="true">
      
      <li class="slide" 
          v-for="item of brands" 
          :key="item.title">
          <div class="slide-inner">
            <div class="brand">
              <div class="content-inner">
                <img class="active-logo" 
                  :src="require('@' + '/assets/brands/' + item.activeLogo + '.png')"
                  :alt="item.title + ' Logo'"
                  :title="item.title" />

                <img class="inactive-logo" 
                  :src="require('@' + '/assets/brands/' + item.inactiveLogo + '.png')"
                  :alt="item.title + ' Logo'"
                  :title="item.title" />
              </div>   
            </div>
          </div>
      </li>
    </slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import { Brands } from '@/content/Brands.js'

export default {
  components: { 
    Slider 
  },
  name: 'BrandSlider',
  data () {
    return {
      brands: []
    }
  },
  created () {
    this.brands = Brands
  }
}
</script>