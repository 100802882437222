<template>
  <div class="partner-slider con-p">
    <div class="uk-container">
      <div class="content">
        <h2>Our Partners</h2>
        <p>Collaboration is most powerful when you’re working with talented people. We’re proud to call these our partners
            in providing tailored Cloud solutions for our clients.</p>
      </div>
    </div>

    <slider :items="partners" 
      identifier="partners" 
      :responsive="numberOfSlides"
      :autoplay="true"
      :pauseOnHover="true"
      colorScheme="secondary">

      <li class="slide" 
          v-for="item of partners" 
          :key="item.title">
          <div class="slide-inner">
            <div class="partner">
              <div class="content-inner">
                <img class="active-logo" 
                  :src="require('@' + '/assets/partners/' + item.activeLogo + '.png')"
                  :alt="item.title + ' Logo'"
                  :title="item.title" />

                <img class="inactive-logo" 
                  :src="require('@' + '/assets/partners/' + item.inactiveLogo + '.png')"
                  :alt="item.title + ' Logo'"
                  :title="item.title" />
              </div>   
            </div>
          </div>
      </li>
    </slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import { Partners } from '@/content/Partners.js'

export default {
  components: { 
    Slider 
  },
  name: 'PartnerSlider',
  data () {
    return {
      partners: [],
      numberOfSlides: {
          small: 1,
          medium: 3,
          large: 3,
          xlarge: 3
        }
    }
  },
  created () {
    this.partners = Partners
  }
}
</script>