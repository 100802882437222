export const Brands = [
  // {
  //   title: 'Scotiabank',
  //   activeLogo: 'scotiabank',
  //   inactiveLogo: 'scotiabankBW'
  // },
  {
    title: 'CIBC',
    activeLogo: 'cibc',
    inactiveLogo: 'cibcBW'
  },
  // {
  //   title: 'ATB',
  //   activeLogo: 'atb',
  //   inactiveLogo: 'atbBW'
  // },
  // {
  //   title: 'TMX',
  //   activeLogo: 'tmx',
  //   inactiveLogo: 'tmxBW'
  // },
  {
    title: 'BMO',
    activeLogo: 'bmo',
    inactiveLogo: 'bmoBW'
  },
  {
    title: 'PC Financial',
    activeLogo: 'pc-financial',
    inactiveLogo: 'pc-financialBW',
  },
  // {
  //   title: 'Telus',
  //   activeLogo: 'telus',
  //   inactiveLogo: 'telusBW'
  // },
  {
    title: 'Cogeco',
    activeLogo: 'cogeco',
    inactiveLogo: 'cogecoBW'
  },
  {
    title: 'Cameco',
    activeLogo: 'cameco',
    inactiveLogo: 'camecoBW'
  },
  {
    title: 'Keyera',
    activeLogo: 'keyera',
    inactiveLogo: 'keyeraBW'
  },
  {
    title: 'Firstlight Media',
    activeLogo: 'firstlight-media',
    inactiveLogo: 'firstlight-mediaBW'
  },
  {
    title: 'W Games',
    activeLogo: 'w-games',
    inactiveLogo: 'w-games'
  },
  {
    title: 'Broker Bay',
    activeLogo: 'broker-bay',
    inactiveLogo: 'broker-bayBW'
  },
  {
    title: 'Eyereturn Marketing',
    activeLogo: 'eye-return-marketing',
    inactiveLogo: 'eye-return-marketingBW'
  },
  {
    title: 'Therapia',
    activeLogo: 'therapia',
    inactiveLogo: 'therapiaBW'
  },
  {
    title: 'Hamilton Health Science',
    activeLogo: 'hamilton-health-science',
    inactiveLogo: 'hamilton-health-scienceBW'
  },
]