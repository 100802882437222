<template>
  <div class="case-study-slider">
    <div class="uk-container">
      <div class="case-study-container">
         <slider :items="caseStudies" 
          identifier="case-studies" 
          :responsive="numberOfSlides">

          <li v-for="caseStudy of caseStudies" 
            :key="caseStudy.id" 
            class="case-study-item">
            
            <div class="case-study-flexbox">
              <div class="main-content-left">
                <h2>Case studies</h2>

                <p>{{ caseStudy.introduction }}</p>
                <p>Industry: {{ caseStudy.industry }}</p>
                <p>Primary project location: {{ caseStudy.projectLocation }}</p>
                <p>{{ caseStudy.content }}</p>

                <div class="case-study-testimonial">
                  <div class="testimonial">{{ caseStudy.testimonial }}</div>
                  <span class="author">{{ caseStudy.testimonialAuthor }}</span>
                </div>
              </div>

              <div class="main-content-right">
                <div class="content-block">
                  <div class="icon icon-case-study">Y</div>
                  <div class="content">
                    <div class="content-inner">
                      <h3>The challenge</h3>
                      <p><span v-html="caseStudy.challenge"></span></p>
                    </div>
                  </div>
                </div>

                <div class="content-block">
                  <div class="icon">R</div>
                  <div class="content">
                    <div class="content-inner">
                      <h3>The solution</h3>
                      <p><span v-html="caseStudy.solution"></span></p>
                    </div>
                  </div>
                </div>

                <div class="content-block">
                  <div class="icon">S</div>
                  <div class="content">
                    <div class="content-inner">
                      <h3>The results</h3>
                      <p><span v-html="caseStudy.results"></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </slider>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import { CaseStudies } from '@/content/CaseStudies.js'

export default {
  components: { 
    Slider 
  },
  name: 'CaseStudySlider',
  data () {
    return {
      caseStudies: [],
      numberOfSlides: {
        small: 1,
        medium: 1,
        large: 1,
        xlarge: 1
      }
    }
  },
  created () {
    this.caseStudies = CaseStudies
  }
}
</script>