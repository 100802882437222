<template>
  <div id="careers-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement">
    </page-hero>

    <div class="careers-container">
      <div class="uk-container">
        <div class="title-container">
          <h2>Find the right role for you</h2>
        </div>

        <div class="careers flexbox">
          <slider :items="careers" 
            identifier="careers" 
            :centered="false"
            :responsive="numberOfSlides">

            <li class="career" v-for="career of careers" :key="career.text">
              <a 
                :href="career.hostedUrl"
                target="_blank">
                <div class="inner-container">
                  
                  <div class="icon"></div>
                  <div class="title">
                    <div class="title-text">{{ career.text }}</div>
                    <div class="arrow">></div>
                  </div>
                </div>
              </a>
            </li>
          </slider>
        </div>
      </div>
    </div>

    <brand-slider></brand-slider>

    <blog bgColor="white"></blog>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import PageHero from '@/layouts/PageHero.vue'
import BrandSlider from '@/layouts/BrandSlider.vue'
import Blog from '@/layouts/Blog.vue'
import * as Careers from '@/content/Careers.js'

export default {
  metaInfo() {
    return {
      title: 'Careers',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    BrandSlider,
    Blog,
    Slider
  },
  name: 'Careers',
  data () {
    return {
      axios: require('axios'),
      pageTitle: 'Interested in working alongside industry experts?',
      titleStatement: 'We’re always on the lookout for talented, hardworking people who can complement our team.',
      numberOfSlides: {
        small: 1,
        medium: 3,
        large: 4,
        xlarge: 4
      },
      careers: []
    }
  },
  async created () {
    await this.axios.get(this.$jobsUrl)
      .then((response) => {
        this.careers = response.data;
      })
  }
}
</script>