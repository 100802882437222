<template>
  <div class="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Badal',
    titleTemplate: '%s | Badal'
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
  @import "@/styles/main.scss";
</style>
