<template>
  <footer id="footer">
    <div class="uk-container">
      <div class="footer-company-contact">
        <div class="inner">
          <h2>CONTACT</h2>
          <div class="address">
            <div class="street">76 Richmond St East, Suite 350</div>
            <div class="city">Toronto, Ontario</div>
            <div class="country">Canada</div>
            <div class="postal">M5C 1P1</div>
          </div>
          <a class="m" href="mailto:info@badal.io" target="_blank">
            info@badal.io
          </a>
        </div>
      </div>

      <div class="footer-nav-container">
        <div class="inner">
          <h2>DISCOVER</h2>
          <div id="footer-nav">
            <div class="link-container">
              <router-link to="/" class="footer-link">Home</router-link>
            </div>
            <div class="link-container">
              <router-link to="/about" class="footer-link">About</router-link>
            </div>
            <div class="link-container">
              <router-link to="/blogs" class="footer-link">Blogs</router-link>
            </div>
            <div class="link-container">
              <router-link to="/careers" class="footer-link"
                >Careers</router-link
              >
            </div>
            <div class="link-container">
              <router-link to="/services" class="footer-link"
                >Services</router-link
              >
            </div>
            <div class="link-container">
              <router-link to="/work" class="footer-link">Work</router-link>
            </div>
            <div class="link-container">
              <router-link to="/privacy-policy" class="footer-link"
                >Privacy Policy</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="footer-contact">
        <div class="inner">
          <div class="social">
            <h2>CONNECT WITH US</h2>
            <div class="social-links">
              <div class="social-link-container" v-if="$facebookURL">
                <a
                  :href="$facebookURL"
                  target="_blank"
                  class="social-link fb"
                  title="Facebook"
                >
                  <span>Facebook</span>
                </a>
              </div>
              <div class="social-link-container" v-if="$youtubeURL">
                <a
                  :href="$youtubeURL"
                  target="_blank"
                  class="social-link yt"
                  title="Youtube"
                >
                  <span>Youtube</span>
                </a>
              </div>
              <div class="social-link-container" v-if="$linkedInURL">
                <a
                  :href="$linkedInURL"
                  target="_blank"
                  class="social-link in"
                  title="LinkedIn"
                >
                  <span>linkedIn</span>
                </a>
              </div>
              <div class="social-link-container" v-if="$twitterURL">
                <a
                  :href="$twitterURL"
                  target="_blank"
                  class="social-link tw"
                  title="Twitter"
                >
                  <span>Twitter</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
