<template>
  <div :class="['circle-image', align, size]" v-if="src">
    <img 
      :src="require('@' + '/assets/' + src)" 
      :alt="altText" 
      :title="altText" 
    />
  </div>
</template>

<script>

export default {
  name: 'CircleImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    altText: {
      type: String,
      default: 'Placeholder'
    },
    align: {
      type: String,
      default: 'left'
    },
    size: {
      type: String,
      default: ''
    }
  },
}
</script>