<template>
  <div class="testimonial-slider con-p">
    <div class="uk-container">
      <div class="heading-container">
        <h2>Success Stories</h2>
      </div>

      <slider :items="stories" 
        identifier="stories" 
        :autoplay="false"
        :pauseOnHover="false"
        :responsive="numberOfSlides">

        <li 
          class="testimonial-item"
          v-for="item in stories" 
          :key="item.name">
          
            <div v-show="item.logo === ''" :style="{'padding': '20px'}" class="name">
              {{ item.name }}
            </div>

            <div >
            <img :style="{'max-width': '250px', 'max-height': '250px','padding-bottom': '20px'}" :src="require('@' + '/assets/brands/' + item.logo + '.png')"/>
            </div>

            <div  :style="{'padding': '20px'}" class="name">
              {{ item.title }}
            </div>

            <div class="testimonial">
              {{ item.testimonial }}
            </div>

            <div class="link-container">
              <a class="successStory" :href="item.link" target="_blank">Full Article</a>
            </div>
        </li>
      </slider>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import { Stories } from '@/content/Stories.js'

export default {
  components: { 
    Slider
  },
  name: 'StoriesSlider',
  data () {
    return {
      stories: [],
      numberOfSlides: {
        small: 1,
        medium: 1,
        large: 1,
        xlarge: 1
      }
    }
  },
  created () {
    this.stories = Stories
  }
}
</script>


<style lang="scss">
.successStory {
            font: normal normal 900 18px/45px 'Neue Haas Grotesk Display';
            letter-spacing: 0.9px;
             color: #211B41 !important;
          }

.testimonial-slider {
  .uk-slider-container {

    &:before {
      left: -10px;
      top: 150px;
      content: '' !important;
    }

    &:after {
      right: -20px;
      bottom: -100px;
      content: '' !important;
    }
  }
}

</style>