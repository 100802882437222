export const Partners = [
  {
    title: 'Google Cloud Premier Partner',
    activeLogo: 'gc-partners',
    inactiveLogo: 'gc-partnersBW',
  },
  {
    title: 'Looker Consulting Partner',
    activeLogo: 'looker',
    inactiveLogo: 'lookerBW',
  },
  {
    title: 'Collibra',
    activeLogo: 'collibra',
    inactiveLogo: 'collibraBW',
  },
  {
    title: 'Databricks',
    activeLogo: 'databricks',
    inactiveLogo: 'databricksBW',
  },
  {
    title: 'Hashicorp',
    activeLogo: 'hashicorp',
    inactiveLogo: 'hashicorp',
  },
  {
    title: 'Secoda',
    activeLogo: 'secoda',
    inactiveLogo: 'secodaBW',
  }
]