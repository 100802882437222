<template>
  <div id="casestudy-page" class="page-view">
    <page-hero
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement"
      :hideButton="true"
      :hideBreadCrumbs="true"
      >
    </page-hero>

    <div class="main-content-container">
      <div class="uk-container">
        <div class="container-inner">
          <div class="main-content-left">
            <div class="main-content-title-container">
              <h2>Case studies</h2>
              <p>Cameco Corporation is the world's largest publicly traded uranium company, based in Saskatchewan, Canada. Cameco operates in a highly regulated industry with strong compliance and security requirements.</p>
              <p><b>Industry:</b> Energy</p>
              <p><b>Primary project location:</b> Canada</p>
              <p>Badal was brought in to help Cameco establish a practice, on using GCP, that satisfied the needs of systems, networking and security teams. End goal was to be able to allow business users to consume cloud services in a secure and compliant manner without impacting velocity.</p>
            </div>
          </div>
          <div class="main-content-right">
            <div class="content-block">
              <div style="height: 100%;" class="icon icon-case-study">Y</div>
              <div class="content">
                <div class="content-inner">
                  <h3>The challenge</h3>
                  <p>
                    Cameco’s IT department were facing challenges in productionalizing their cloud usage because the cloud’s “shared responsibility” model didn’t fit Cameco’s internal operating model. With the ever-going product suite GCP offers and Cameco’s increased need to utilize these tools, Cameco was looking to build a practice that allowed consumption of services [on GCP] in a secure and compliant manner.<br/>
                    There were a few challenges that needed to be addressed:<br/>
                    <ul>
                      <li>Tooling to support consistent deployment with a focus on security</li>
                      <li>Change Process around business utilization of cloud</li>
                      <li>Foundational secure design that adhered to regulatory compliance (networking controls, authentication & authorization, policies etc…)</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div class="content-block">
              <div style="height: 100%;" class="icon">R</div>
              <div class="content">
                <div class="content-inner">
                  <h3>The solution</h3>
                  <p>
                    Due to the varied nature of services exposed by GCP, our focus was broken into 2 streams.<br/>
                    <ol style="text-align: left;">
                      <li>Build a base foundation [for cloud usage] that enables best practices with security as a focus.</li>
                      <li>Take the implementation from point 1 and apply it to a real business use case. End goal, each use case will derive new changes that will evolve the cloud practice.</li>
                    </ol>
                    For Cameco, building a proper base foundation required introduction and implementation of a couple of items:
                    <ul style="text-align: left;">
                      <li>Tooling that supported a more DevOps approach to functioning in the cloud</li>
                      <li>Implementing an operating model for all the dispersed teams to participate in a shared responsibility model. This included a change management process that had the appropriate stakeholders involved throughout the journey.</li>
                      <li>Building a secure, consistent, extensible, repeatable and automated GCP foundation with security as the primer</li>
                    </ul>
                    With the above items in place, we were able to take a real business use case and run it through the implemented process.
                  </p>
                </div>
              </div>
            </div>

            <div class="content-block">
              <div style="height: 100%;" class="icon">S</div>
              <div class="content">
                <div class="content-inner">
                  <h3>The results</h3>
                  <p>With a proper foundation in place, Cameco was able to scale-in more business use cases onto GCP. All the appropriate stakeholders felt they had good insight and control into providing a platform that business units could securely and safely utilize.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swirl-container"></div>
    <brand-slider></brand-slider>
    <partner-slider></partner-slider>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import BrandSlider from '@/layouts/BrandSlider.vue'
import PartnerSlider from '@/layouts/PartnerSlider.vue'
import { BadalAttributes } from '@/content/About.js'

export default {
  metaInfo() {
    return {
      title: 'CaseStudy',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    BrandSlider,
    PartnerSlider
  },
  name: 'CaseStudy',
  data () {
    return {
      pageTitle: 'Cloud Foundations',
      titleStatement: 'Building a cloud infrastructure practice with security in mind.',
      badalAttributes: []
    }
  },
  created () {
    this.badalAttributes = BadalAttributes
  }
}
</script>