<template>
  <div id="work-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement">
    </page-hero>

    <social-side-bar></social-side-bar>

    <case-study-slider></case-study-slider>
    
    <testimonial-slider></testimonial-slider>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import TestimonialSlider from '@/layouts/TestimonialSlider.vue'
import CaseStudySlider from '../layouts/CaseStudySlider.vue'
import SocialSideBar from '../layouts/SocialSideBar.vue'

export default {
  metaInfo() {
    return {
      title: 'Work',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    TestimonialSlider,
    CaseStudySlider,
    SocialSideBar
  },
  name: 'Work',
  data () {
    return {
      pageTitle: 'The 6Cloud process in action',
      titleStatement: 'Here’s a small sample of how we’ve helped our clients streamline their organizations, and take their businesses to the next level.'
    }
  }
}
</script>