<template>
  <div id="career-single-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement"
      :isSingle="true">
    </page-hero>

    <social-side-bar></social-side-bar>

    <div class="main-content-container">
      <div class="uk-container">
        <div class="container-inner">
          <!-- <div class="main-content-left" v-if="$route.params.id != 'lead-sre-devops-engineer'">
            <div class="main-content-title-container">
              <h2>{{ career.title }}</h2>

              <p v-for="(description, index) of career.description" :key="index">
                {{ description }}
              </p>
            </div>
          </div>

          <div class="main-content-right" v-if="$route.params.id != 'lead-sre-devops-engineer'">
            <div class="content-block">
              <div class="icon career-icon">1</div>
              <div class="content">
                <div class="content-inner">
                  <h3>The requirements</h3>
                  <p>{{ career.requirements }}</p>
                </div>
              </div>
            </div>
            <div class="content-block">
              <div class="icon">S</div>
              <div class="content">
                <div class="content-inner">
                  <h3>Experience needed</h3>
                  <p>{{ career.experience }}</p>
                </div>
              </div>
            </div>
            <div class="content-block">
              <div class="icon">R</div>
              <div class="content">
                <div class="content-inner">
                  <h3>The results</h3>
                  <p>{{ career.results }}</p>
                </div>
              </div>
            </div>
          </div> -->

          <div class="main-content">
            <h2>{{ career.title }}</h2>
            <div class="career-content" v-html="career.content"></div>
          </div>

          <div class="form-container">
            <form v-on:submit.prevent="formHandler()">
              <div class="input-container left">
                <div class="input-wrapper">
                  <label class="uk-form-label" for="career-first-name">First Name</label>
                  <input 
                    id="career-first-name"
                    class="uk-input uk-form-width-large" 
                    type="text" 
                    placeholder="First Name" 
                    v-model="formData.firstName" 
                    required="true"
                  >
                </div>


                <div class="input-wrapper">
                  <label class="uk-form-label" for="career-last-name">Last Name</label>
                  <input 
                    id="career-last-name"
                    class="uk-input uk-form-width-large" 
                    type="text" 
                    placeholder="Last Name" 
                    v-model="formData.lastName" 
                    required="true"
                  >
                </div>
              </div>

              <div class="input-container right">
                <div class="input-wrapper">
                  <label class="uk-form-label" for="upload-resume">Upload resume</label>
                  <div uk-form-custom>
                    <input 
                      type="file" 
                      id="upload-resume" 
                      ref="resume" 
                      v-on:change="handleFileUpload('resume')" 
                      required="true"
                    >
                    <button class="uk-button uk-button-default" type="button" tabindex="-1">Browse</button>
                  </div>
                </div>
                
                <div class="input-wrapper">
                  <label class="uk-form-label" for="upload-cover-letter">Upload cover letter</label>
                  <div uk-form-custom>
                    <input 
                      type="file" 
                      id="upload-cover-letter" 
                      ref="coverLetter" 
                      v-on:change="handleFileUpload('coverLetter')"
                    >
                    <button class="uk-button uk-button-default" type="button" tabindex="-1">Browse</button>
                  </div>
                </div>
              </div>

              <div class="controls">
                <button class="form-submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import SocialSideBar from '../layouts/SocialSideBar.vue'
import * as Careers from '@/content/Careers.js'

export default {
  metaInfo() {
    return {
      title: `${this.career.title}`,
       meta: [
        { name: 'description', content: `${this.career.description[0] }` },
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.career.title}`},
        { property: 'og:description', content: `${this.career.description[0] }`},
        { property: 'og:url', content: `/careers/${this.$route.params.id}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.career.title}`},
        { property: 'twitter:description', content: `${this.career.description[0] }`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
  components: { 
    PageHero,
    SocialSideBar
  },
  name: 'CareerSingle',
  data () {
    return {
      pageTitle: 'Choose the best career path for your future',
      titleStatement: 'We’re always on the lookout for talented, hardworking people who can complement our team.',
      careers: [],
      career: {},
      axios: require('axios'),
      formData: {
        to: '',
        from: '',
        firstName: '',
        lastName: '',
        resume: '',
        coverLetter: ''
      },
    }
  },
  created () {
    this.formData.to = this.$toEmailAddress
    this.formData.from = this.$fromEmailAddress

    for (const career of Object.entries(Careers)) {
      // Pull object out of Career module
      this.careers.push(career[1])
    }

    // Filter out selected career from careers array
    this.career = this.careers.find(obj => {
      return obj.permalink === this.$route.params.id
    })
  },
  methods: {
    formHandler() {
      let formData = new FormData()
      formData.append('first_name', this.formData.firstName)
      formData.append('last_name', this.formData.lastName)

      if(this.formData.resume) {
        formData.append('resume', this.formData.resume)
      }

      if(this.formData.coverLetter) {
        formData.append('cover_letter', this.formData.coverLetter)
      }

      this.axios.post(this.careerFormPostUrl, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        // Form Success Handler
      }).catch(() => {
        // Form Failure Handler
      })
    },
    handleFileUpload (field) {
      this.formData[field] = this.$refs[field].files[0]
    }
  }
}
</script>