<template>
  <div :class="['slider-container', colorScheme]">
    <div class="uk-slider" :uk-slider="sliderOptions" >
      <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
          <div class="uk-slider-container">
                <ul :id="`slider-${identifier}`" :class="[
                    'uk-slider-items', 
                    `uk-child-width-1-${responsive.small}@s`,
                    `uk-child-width-1-${responsive.medium}@m`,
                    `uk-child-width-1-${responsive.large}@l`,
                    `uk-child-width-1-${responsive.xlarge}@xl`,
                    'uk-grid'
                ]">
                <slot></slot>
            </ul>
          </div>
      </div>

      <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin">
        <li class="uk-dotnav-item" 
          v-for="(item, index) in items" 
          :key="`${identifier}-${index}`" 
          :uk-slider-item="index">
          
          <a class="slider-dotnav-link">
            <span>{{ identifier }} {{ index }}</span>
          </a>
        </li>
      </ul> 
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: 'Slider',
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayInterval: {
      type: Number,
      default: 7000
    },
    pauseOnHover: {
      type: Boolean,
      default: false
    },
    identifier: {
      type: String,
      default: 'default'
    },
    centered: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    colorScheme: {
      type: String,
      default: 'default' // default, secondary
    },
    responsive: {
      type: Object,
      default: () => {
        return {
          small: 2,
          medium: 2,
          large: 4,
          xlarge: 6
        }
      }
    
    }
  },
  data () {
    return {
      initialized: false,
      sliderOptions: `
        center: ${this.centered}; 
        index: ${this.index}; 
        autoplay: ${this.autoplay}; 
        autoplay-interval: ${this.autoplayInterval}; 
        pause-on-hover: ${this.pauseOnHover}`,
      internalIndex: 1,
    }
  
  },
  mounted () {
    // Apply class to centered slide
    const slider = document.getElementById(`slider-${this.identifier}`)

    UIkit.util.on(`#slider-${this.identifier}`, 'itemshow', (i) => {
      const slides = slider.getElementsByClassName('slide')
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove('centered')
      }

      i.target.classList.add('centered')
    })
  }
}
</script>