<template>
  <div id="privacy-policy" class="page-view uk-container">
    <h1>Badal Website Privacy Policy</h1>
    <h2>Privacy Policy</h2>
    <h3>Privacy is important to us.</h3>
    <p>Revised as of April 16th, 2024</p>
    <p>
      This privacy policy discloses Badal’s privacy practices when interacting
      with Badal through
      <a href="https://badal.io">https://badal.io</a> (“Website”), network
      applications, and other interactive services; herein we provide
      information on how we collect, use, disclose, manage and protect your
      personal information.
    </p>
    <h3>How We Collect Your Information</h3>
    <p>Badal may collect information from you through the following methods:</p>
    <ul>
      <li>
        Contacting us (either through our website, by phone or via email);
        and/or
      </li>
      <li>Downloading our marketing materials.</li>
      <li>Visiting our website (Google Analytics)</li>
    </ul>
    <h3>The Information We Collect</h3>
    <p>
      We collect information that you voluntarily give us via email or other
      direct contact methods. This can include personally identifiable
      information such as
    </p>
    <ul>
      <li>Your first and last name;</li>
      <li>E-mail address;</li>
      <li>Professional background; and</li>
      <li>Phone number.</li>
    </ul>
    <h3>What We Do With Your Information</h3>
    <p>
      We will use your information to respond to your inquiry, notify you about
      changes on our website or service offering, and improve our website and
      services.
    </p>
    <h3>How We Protect Your Information</h3>
    <p>
      We take appropriate security measures to prevent unauthorized access to
      your information, including administrative, technical, and access control
      policies. Badal also uses standard online security protocols to encrypt
      the transmission of sensitive information.
    </p>
    <p>
      We restrict access to personal information to our employees, contractors,
      agents and authorized third parties who need to know the information to
      operate, develop or improve our services. These individuals are bound by
      confidentiality obligations. When these individuals “need to know”
      expires, their access to your personal information is thereafter
      prohibited.
    </p>
    <h3>Your Rights</h3>
    <p>
      You are entitled to exercise certain rights with respect to your personal information:
    </p>
    <ul>
      <li>
          Access Your Personal Information: You have the right to access the records Badal holds containing your
          personal information.
      </li>
      <li>
          Correct Your Personal Information: You have the right to request correction of your personal information in
          case you suspect Badal holds inaccurate or out-of-date personal information related to you.
      </li>
      <li>
          Withdraw Your Consent: If you want to withdraw your consent for Badal to collect and process your personal
          information, you have the right to do so. Please note that withdrawal of your consent is not the same as
          requesting to delete your personal information.
      </li>
      <li>
          Delete Your Personal Information: If you no longer want Badal to hold and process your personal information,
          you have the right to request deletion of your personal information.
      </li>
      <li>
          Make A Complaint: If you believe that Badal has not complied with this Privacy Statement or with applicable
          privacy legislation, you are entitled to make a complaint to the relevant authorities.
      </li>
    </ul>
    <h3>Exercising Your Rights</h3>
    <p>
      For any inquiries related to privacy, please contact Badal's Privacy Team at <a
        href="mailto:privacy@badal.io">privacy@badal.io</a>.
    </p>
    <h3>Changes to Our Privacy Policy</h3>
    <p>
      Badal may change this Policy from time to time. Please note the “Revised as of” note above to see when the most
      recent version was revised. Your use of the Website following any changes will acknowledge acceptance of the
      updated Policy.
    </p>
    <h3>Terms and Conditions</h3>
    <p>
      By using our website and our services, you authorize the collection, use,
      storage, sharing, and disclosure of your personal information as described
      in this Privacy Policy and any applicable updates. If you do not agree
      with the data practices described in this Privacy Policy, you should not
      use our websites or services.
    </p>
    <h3>Jurisdiction</h3>
    <p>
      This website originates in Canada. It shall be governed by and construed
      in accordance with the laws of the province of Ontario, Canada. All
      disputes, proceedings or claims arising out of or in connection with this
      website shall be submitted and be subject to the exclusive jurisdiction of
      the Courts of the province of Ontario, Canada.
    </p>
  </div>
</template>
<style>
.uk-sticky .uk-sticky-fixed {
  display: none !important;
}

.uk-container {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
<script>
export default {
  metaInfo() {
    return {
      title: "Badal Events",
      meta: [
        { name: "description", content: `${this.titleStatement}` },
        { name: "robots", content: "index, follow" },
        { property: "og:type", content: "article" },
        { property: "og:title", content: `${this.pageTitle}` },
        { property: "og:description", content: `${this.titleStatement}` },
        { property: "og:url", content: `${this.$route.path}` },
        { property: "og:site_name", content: "BADAL" },
        { property: "twitter:title", content: `${this.pageTitle}` },
        { property: "twitter:description", content: `${this.titleStatement}` },
        { property: "twitter:site", content: "BADAL" },
      ],
    };
  },
  components: {},
  name: "PrivacyPolicy",
  data() {
    return {
      pageTitle: "Badal Privacy Policy",
      titleStatement: "We are your Cloud experts.",
      cloud6Processes: [
        {
          title: "Plan",
          icon: "A",
        },
        {
          title: "Deploy",
          icon: "B",
        },
        {
          title: "Automate",
          icon: "C",
        },
        {
          title: "Secure",
          icon: "D",
        },
        {
          title: "Manage",
          icon: "E",
        },
        {
          title: "Enterprise",
          icon: "F",
        },
      ],
    };
  },
};
</script>
