export const LeadSreDevOpsEngineer = {
  title: 'Lead SRE/DevOps Engineer (GCP)',
  description: 'Badal.io is hiring a Lead SRE/DevOps Engineer (GCP)',
  permalink: 'lead-sre-devops-engineer',
  content: `
  <h3>About Badal.io</h3>
  <p>We are a boutique, rapidly growing, GCP (Google Cloud Platform) consulting company based out of Toronto. We work with GCP’s top customers (banking, telco, energy, retail, etc. ) 
    to help them with cloud transformation, security, analytics, ML, data governance, etc. Clients usually engage us to solve their hardest business problems, and help raise the bar at their organization. </p>
  <h3>Why Badal?</h3> 
  <p>You get the best of both worlds. We operate like an early stage startup, with all the associated benefits (talent, growth, learning opportunities, flexibility) 
    but get to solve enterprise level technical challenges, and are actually profitable ;)</p>
  <ul>
    <li>People: We hire top tier talent. Our team consists of ex-Googlers, YCombinator Alumni, and individuals that built software for 400M users.</li>
    <li>GCP is the Best Cloud: Maybe we are biased, but GCP is the most cutting edge cloud provider, building on technologies that have been pioneered by Google : BigQuery, K8s/Anthos, Vertex AI, etc.</li>
    <li>Growth: We have doubled in size in the last 6 months, and are looking to double again this year. </li>
  </ul>
  <h3>You Will</h3>
  <ul>
    <li>Work closely with technical leads and client teams to fully demonstrate the benefits of GCP technology </li>
    <li>Introduce clients to cloud best practices such as CI/CD, infrastructure as code, canonization, monitoring, etc.</li>
    <li>Setup GCP foundation, networking and security best practices using Terraform</li>
    <li>Help build and monitor microservices running on K8s</li>
    <li>Engineer tools and services that improve operational efficiency and help our developers build features faster</li>
    <li>Define and implement cloud-based solutions in line with industry best practices and enterprise architecture guidelines</li>
    <li>Support clients in troubleshoot issues in their test and/or production environments and identifying root cause and solutions</li>
  </ul>
  <h3>You Have</h3>
  <ul>
    <li>GCP experience ( for exceptional talent with other cloud experience, we will provide a training programs)</li>
    <li>Experience in large-scale, secure, and high availability solutions in Cloud environment such as Google Cloud (GCP) </li>
    <li>Experience with Infrastructure as Code tools such as Terraform, CloudFormation, or Config Connector</li>
    <li>Extensive programming experience in Python, Go, or Java </li>
    <li>Good understanding of containerization technologies such as Kubernetes (GKE) or Docker </li>
    <li>Good understanding of GCP services such as IAM, Networking components, Managed Instance Groups, and Google Cloud Storage buckets </li>
    <li>An understanding of security principals and other associated controls and features in GCP </li>
    <li>Database experience with either RDBM or NOSQL</li>
    <li>Technical writing, experience in preparing and presenting technical material to a variety of audiences</li>
    <li>Experience in working in, and with, Agile delivery teams </li>
  </ul>
  <h3>Nice To Have</h3>
  <ul>
    <li>Strong K8s, preferably Anthos, knowledge</li>
    <li>Experience with Policy-as-Code (such as OPA)</li>
    <li>Strong understanding of Google SRE best practices</li>
  </ul>
  <h3>Type of qualities we look for (across all roles)</h3>
  <ul>
    <li>Passionate about delivering high quality commercial software products and platforms to market</li>
    <li>Team player. We are a small team, and enjoy working with each other and our clients - we would like it to keep it the same way as we grow</li>
    <li>Strong understanding of modern software engineering processes</li>
    <li>Client focused and passionate about delivering business value with</li>
    <li>Able to communicate clearly and efficiently with a variety of audiences including developers, clients, customers, partners and executives</li>
    <li>Flexible and willing to use the right technology for each problem in the context of timelines and business goals</li>
    <li>Ability to complete the job regardless of the circumstance</li>
  </ul>
  <h3>Our Benefits</h3>
  <ul>
    <li>Competitive compensation with an equity option</li>
    <li>Flexible vacation policy. Three weeks of vacation, plus we are closed over the winter holidays (+ ~1 week)</li>
    <li>Certification opportunities. We will pay for your GCP certification as well as other relevant training and certifications</li>
    <li>Learning opportunities. We work across different industries, technologies and roles and will work with you to help you explore your interests</li>
    <li>Blogging, open source, meetups and conference opportunities. We will provide a platform and time for your to pursue your ideas</li>
    <li>No politics environment. Our management team is all engineers that just like to get things done </li>
  </ul>
  <p>Badal is an equal opportunity employer committed to creating a safe, diverse and inclusive environment. We encourage qualified applicants of all backgrounds including ethnicity, 
    religion, disability status, gender identity, sexual orientation, family status, age, nationality, and education levels to apply. 
    If you are contacted for an interview and require accommodation during the interviewing process, please let us know.
  </p>
  `
}

export const SeniorDataEngineer = {
  title: 'Senior Data Engineer (GCP)',
  description: 'Badal.io is hiring a Senior Data Engineer (GCP)',
  permalink: 'senior-data-engineer',
  content: `
  <h3>About Badal.io</h3>
  <p>We are a boutique, rapidly growing, GCP (Google Cloud Platform) consulting company based out of Toronto. We work with GCP’s top customers (banking, telco, energy, retail, etc. ) 
    to help them with cloud transformation, security, analytics, ML, data governance, etc. Clients usually engage us to solve their hardest business problems, and help raise the bar at their organization. </p>
  <h3>Why Badal?</h3> 
  <p>You get the best of both worlds. We operate like an early stage startup, with all the associated benefits (talent, growth, learning opportunities, flexibility) 
    but get to solve enterprise level technical challenges, and are actually profitable ;)</p>
  <ul>
    <li>People: We hire top tier talent. Our team consists of ex-Googlers, YCombinator Alumni, and individuals that built software for 400M users.</li>
    <li>GCP is the Best Cloud: Maybe we are biased, but GCP is the most cutting edge cloud provider, building on technologies that have been pioneered by Google : BigQuery, K8s/Anthos, Vertex AI, etc.</li>
    <li>Growth: We have doubled in size in the last 6 months, and are looking to double again this year. </li>
  </ul>
  <h3>You Will</h3>
  <ul>
    <li>Work closely with technical leads and client teams to fully demonstrate the benefits of GCP technology</li>
    <li>Introduce clients to data architecture and analytics best practices </li>
    <li>Solve some of the most challenging and high scale data and IoT problems (telco, energy, financial market data, etc.)</li>
    <li>Write high quality and testable batch and real time data pipelines</li>
    <li>Work with Data Scientists to design pipelines that improve their productivity and enable them to implement their ideas</li>
    <li>Support clients in troubleshoot issues in their test and/or production environments and identifying root cause and solutions</li>
  </ul>
  <h3>You Have</h3>
  <ul>
    <li>GCP experience ( for junior candidates, or exceptional talent with other cloud experience, we will provide a training programs)</li>
    <li>Experience in large-scale, secure, and high availability solutions in Cloud environment such as Google Cloud (GCP)</li>
    <li>Experience with one of Dataflow or Spark</li>
    <li>Experience with orchestration frameworks such as Airflow, Kubeflow, Azkaban, etc.</li>
    <li>Extensive programming experience in Python, Java or Scala</li>
    <li>Good understanding of modern data architecture</li>
    <li>Good understanding of GCP services such as IAM, and Google Cloud Storage buckets</li>
    <li>Experience with business intelligence tools like QuickSight, Looker and Data Studio</li>
    <li>Technical writing, experience in preparing and presenting technical material to a variety of audiences</li>
    <li>Experience in working in, and with, Agile delivery teams</li>
  </ul>
  <h3>Nice To Have</h3>
  <ul>
    <li>Streaming experience with Dataflow, Spark, Flink, Kafka Streams, etc</li>
    <li>Strong understanding of Data Governance principles and experience working with tool such as Collibra or Immuta</li>
  </ul>
  <h3>Type of qualities we look for (across all roles)</h3>
  <ul>
    <li>Passionate about delivering high quality commercial software products and platforms to market</li>
    <li>Team player. We are a small team, and enjoy working with each other and our clients - we would like it to keep it the same way as we grow</li>
    <li>Strong understanding of modern software engineering processes</li>
    <li>Client focused and passionate about delivering business value with</li>
    <li>Able to communicate clearly and efficiently with a variety of audiences including developers, clients, customers, partners and executives</li>
    <li>Flexible and willing to use the right technology for each problem in the context of timelines and business goals</li>
    <li>Ability to complete the job regardless of the circumstance</li>
  </ul>
  <h3>Our Benefits</h3>
  <ul>
    <li>Competitive compensation with an equity option</li>
    <li>Flexible vacation policy. Three weeks of vacation, plus we are closed over the winter holidays (+ ~1 week)</li>
    <li>Certification opportunities. We will pay for your GCP certification as well as other relevant training and certifications</li>
    <li>Learning opportunities. We work across different industries, technologies and roles and will work with you to help you explore your interests</li>
    <li>Blogging, open source, meetups and conference opportunities. We will provide a platform and time for your to pursue your ideas</li>
    <li>No politics environment. Our management team is all engineers that just like to get things done </li>
  </ul>
  <p>Badal is an equal opportunity employer committed to creating a safe, diverse and inclusive environment. We encourage qualified applicants of all backgrounds including ethnicity, 
    religion, disability status, gender identity, sexual orientation, family status, age, nationality, and education levels to apply. 
    If you are contacted for an interview and require accommodation during the interviewing process, please let us know.
  </p>
  `
}