<template>
  <div class="testimonial-slider con-p">
    <div class="uk-container">
      <div class="heading-container">
        <h2>Testimony From Satisfied Clients</h2>
      </div>

      <slider :items="testimonials" 
        identifier="testimonials" 
        :autoplay="false"
        :pauseOnHover="false"
        :responsive="numberOfSlides">

        <li 
          class="testimonial-item"
          v-for="item in testimonials" 
          :key="item.name">
            <div class="image" v-if="imageExists(`testimonials/${item.image}.png`)">
              <circle-image :src='`testimonials/${item.image}.png`' align='center' size="thumb" altText="Placeholder Image"></circle-image>
            </div>

            <div class="name">
              {{ item.name }}
            </div>

            <div class="title">
              {{ item.title }}
            </div>

            <div class="testimonial">
              {{ item.testimonial }}
            </div>
        </li>
      </slider>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import CircleImage from '@/components/CircleImage.vue'
import { Testimonials } from '@/content/Testimonials.js'

export default {
  components: { 
    Slider,
    CircleImage 
  },
  name: 'TestimonialSlider',
  data () {
    return {
      testimonials: [],
      numberOfSlides: {
        small: 1,
        medium: 1,
        large: 1,
        xlarge: 1
      }
    }
  },
  created () {
    this.testimonials = Testimonials
  },
  methods: {
    imageExists (image) {
      try {
        return require(`@/assets/${image}`)
      } catch {
        return ''
      }
    }
  }
}
</script>