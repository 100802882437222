<template>
  <div id="company-event" class="page-view">
    <div class="main-content-container">
      <div class="container">
        <div class="hero">
          <h1 class="pink">Join us!</h1>
          <h1>We’ve grown quite a bit this year &</h1>
          <h1 class="orange">want to celebrate it all with you.</h1>

          <p>
            As an appreciation to our growth, we are hosting our clients for an
            evening of networking, cocktails, and hors d'oeuvres.
          </p>
          <!-- <h2>Kindly respond by November 4</h2> -->
        </div>
        <div class="container-flex">
          <div class="main-content-left">
            <!-- <div class="content" v-html="this.content">{{ this.content }}</div> -->
            <a href="https://goo.gl/maps/i2LiZaTrf8GAAyzU8" target="_blank">
              <img src="../assets/events/map.png" />
            </a>
            <h2 class="orange">
              November 10th, 2022 <br />6:00pm-12:00am<br />Chef's Hall
            </h2>
            <p>
              111 Richmond Street<br />
              Toronto, ON
            </p>
            <h1 class="peach">#65BADALERS</h1>
            <img src="../assets/events/PaintNight.jpg" />
            <img src="../assets/events/BadalNiagaraFallsJuly2022.jpg" />
          </div>
          <div class="main-content-right">
            <form
              action="https://formkeep.com/f/89a6432f7df3"
              accept-charset="UTF-8"
              enctype="multipart/form-data"
              method="POST"
            >
              <center>
                <h2>Enter Your Information Below to Register for Our Event</h2>
                <p style="color:#FF0000">All fields are required</p>
              </center>
              <input type="hidden" name="utf8" value="✓" />
              <div class=" form-input-half">
                <div class="form-input">
                  <label>First Name</label>
                  <input
                    name="firstName"
                    v-model="formData.firstName"
                    required
                  />
                </div>
                <div class="form-input">
                  <label>Last Name</label>
                  <input name="lastName" v-model="formData.lastName" required />
                </div>
              </div>
              <div class="form-input">
                <label for="email-address">Email Address</label>
                <input type="email" id="email-address" name="email" required />
              </div>
              <div class="form-input">
                <label>Company Name</label>
                <input name="company" required />
              </div>
              <div class="form-input">
                <label>Country</label>
                <input name="country" required />
              </div>
              <div class="form-input">
                <label>Dietary Restrictions</label>
                <input name="dietary" />
              </div>
              <div class="form-checkbox">
                <label>I agree to Badal.io's privacy policy</label>
                <input type="checkbox" name="optin" required />
              </div>

              <button type="submit" class="form-submit-btn">Submit</button
              ><br />
              <center>
                <p>
                  For questions or inquiries regarding this event please contact
                  <a href="mailto:events@badal.io" target="_blank"
                    >events@badal.io
                  </a>
                </p>
              </center>
            </form>
            <h2 style="display: none;" ref="thankyou">
              Thank You For Your Submission Your Name Has Been Added To The List
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.uk-sticky .uk-sticky-fixed {
  display: none !important;
}
</style>

<script>
import axios from "axios";
export default {
  metaInfo() {
    return {
      title: "Badal Events",
      meta: [
        { name: "description", content: `${this.titleStatement}` },
        { name: "robots", content: "noindex" },
        { property: "og:type", content: "article" },
        { property: "og:title", content: `${this.pageTitle}` },
        { property: "og:description", content: `${this.titleStatement}` },
        { property: "og:url", content: `${this.$route.path}` },
        { property: "og:site_name", content: "BADAL" },
        { property: "twitter:title", content: `${this.pageTitle}` },
        { property: "twitter:description", content: `${this.titleStatement}` },
        { property: "twitter:site", content: "BADAL" },
      ],
    };
  },
  components: {},
  name: "CompanyEvent",
  data() {
    return {
      content:
        "<h1>November Mingle at Chef’s Hall</h1></br>Place: Chef’s Hall - 111 Richmond St., Toronto, ON</br></br>You’re Invited! Badal is hosting our November Social Event at Chef’s Hall this year and we want YOU, our friends, partners and colleagues, to join us. As usual, there will be networking, delicious food, good drinks and an overall fun time.</br></br>Let’s put together our best semi-formal outfits, unwind and get to know each other more. ",
      pageTitle: "Badal Events",
      titleStatement: "We are your Cloud experts.",
      cloud6Processes: [
        {
          title: "Plan",
          icon: "A",
        },
        {
          title: "Deploy",
          icon: "B",
        },
        {
          title: "Automate",
          icon: "C",
        },
        {
          title: "Secure",
          icon: "D",
        },
        {
          title: "Manage",
          icon: "E",
        },
        {
          title: "Enterprise",
          icon: "F",
        },
      ],
      errorText: "",
      pageData: {},
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        title: "",
        country: "",
        address: "",
        city: "",
        province: "",
        phone: "",
        postal: "",
        privacy: false,
        dietaryRestrictions: "",
      },
    };
  },
  created() {
    axios
      .get(
        "https://script.googleusercontent.com/a/macros/badal.io/echo?user_content_key=KK8DEnAVGkVa2Z8iWsaoLIRlVIND6c7v1AEUNJxDcxMPAYd4k5PdWGaGgx4NMYKSI2wlf7AfmwaVI68eNyvphbebJU9XrWiIm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP-nNXmCgFn2o1EKNoWn58SI-YCdnL_RVNHYtgI39q2sHS2fBN0zaEhjEDzhrMD2ECL0G6sk-MD5pCuXkYRbK0Hsi9UZNI43HC4pwNiueAJn215AxdqFLBoCmoEBJ3qF9sE&lib=MBAaHdlILiDuVeLOtUdviiEA6h0pqfKLC"
      )
      .then((response) => {
        this.content = response.data[0].content;
      });
  },
  methods: {},
};
</script>
