<template>
  <div :class="['blogs-container', 'con-p', bgColor]">
    <div class="uk-container">
      <h2>Valuable Resources</h2>

      <slider :items="blogs" 
        identifier="blogs" 
        :centered="false" 
        :responsive="numberOfSlides">
        <li class="blog" v-for="blog of blogs" :key="blog.title">
          <div class="blog-inner">
            <div class="image-container" v-if="blog.thumbnail.length">
              <img 
                :src="blog.thumbnail" 
                :alt="blog.title" 
                :title="blog.title" 
              />
            </div>

            <div class="blog-title-container">
              <h3>{{ blog.title }}</h3>
            </div>

            <!-- <div class="blog-details-container">
              <div class="date">
                {{ formatDate( blog.pubDate) }}
              </div>

              <div class="summary">
                {{ truncateText(blog.content, 125, '...') }}
              </div>
            </div> -->

            <div class="link-container">
              <a :href="blog.link" target="_blank">Full Article</a>
            </div>
          </div>
        </li>
      </slider>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import moment from 'moment';
import { useRoute } from 'vue-router';

export default {
  components: { 
    Slider 
  },
  props: {
    bgColor: {
      Type: String,
      default: 'blue' // blue, white
    }
  },
  data () {
    return {
      axios: require('axios'),
      blogs: [],
      numberOfSlides: {
        small: 2,
        medium: 2,
        large: 3,
        xlarge: 3
      }
    }
  },
  methods: {
truncateText(text, length, clamp) {
      clamp = clamp || '...';
      const node = document.createElement('div');
      node.innerHTML = text;
      const content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    }
  },
  async created () {
    const route = useRoute();

    if(route.name != 'Big_Data_Analytics'){
    await this.axios.get(this.$blogUrl)
      .then((response) => {
        this.blogs = response.data.items.splice(0, 3)
      })
    }
    else{
    await this.axios.get(this.$blogUrl)
      .then((response) => {
        this.blogs = response.data.items.filter(blog => blog.categories.includes('bigquery'))
      })

    }
  }
}
</script>