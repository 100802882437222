<template>
  <div id="cloudsolutions-page" class="page-view">
    <page-hero
      :pageTitle="pageTitle"
      :titleStatement="titleStatement"
      :hideButton="true"
      :hideBreadCrumbs="true"
      :hideHeroImage="true"
      >
    </page-hero>

    <div class="cloud6-process">
      <div class="uk-container">
        <div class="cloud-process-inner">
          <div class="main-content-left">
            <h2>Our Cloud6 Process</h2>
            <p>Created by our experienced team of architects, engineers and developers, Badal’s Cloud 6 Process is designed to help your enterprise flourish now and well into the future.</p>
          </div>

          <div class="main-content-right">
            <div class="processes">
              <div class="content-block" v-for="service in services" :key="service.title">
                <div class="content-block-inner">
                  <div class="icon">{{ service.icon }}</div>
                  <div class="content">
                    <div class="content-inner">
                      <h3>{{ service.title }}</h3>
                      <p>{{ service.description }}</p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="solutions">
      <div class="uk-container">
        <div class="content">
          <h2>SOLUTIONS THAT FIT YOUR NEEDS</h2>
          <p>Learn the specifics of how our experts can modernize your processes and help you unlock more value from your cloud architecture.</p>
        </div>

        <div class="solutions-content">
          <slider :items="solutions" 
            :responsive="numberOfSlides"
            :centered="false"
            identifier="solutions" 
            colorScheme="secondary">

            <li class="solution" v-for="solution of solutions" :key="solution.title">
              <div :class="['solution-card position-one', 'delay']" >
                <div class="content-inner">
                  <div class="header-container">
                    <h3>{{ solution.title }}</h3>
                  </div>

                  <div class="summary">
                    {{ solution.summary }}
                  </div>

                  <div class="how-it-works">
                    How it works
                  </div>
                </div>
              </div>
              <div :class="['solution-card position-two']">
                <div class="content-inner">
                  <div class="content" v-html="solution.details"></div>
                </div>

                <div class='close'>x</div>
              </div>
            </li>
          </slider>
        </div>  
      </div>
    </div>


    <rounded-bottom-two></rounded-bottom-two>

    <div class="icon-strip uk-text-center">
      <div class="uk-container-expand">
        <ul class="uk-list">
          <li class="" v-for="icon in iconStrip" :key="icon.title">
            <div class="icon-wrap">
              <img :src="require('@' + '/assets/svgs/data-analytics-icon-strip/' + icon.logo +'.svg')"/>
            </div>
            <div class="icon-title">{{ icon.title }}</div>
          </li>
        </ul>
      </div>
    </div>

    <blog></blog>

    <success-stories-slider></success-stories-slider>

    <brand-slider></brand-slider>
    </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import { DataSolutions, Services } from '@/content/Services.js'
import RoundedBottomTwo from '@/components/RoundedBottomTwo.vue'
import Slider from '@/components/Slider.vue'
import SuccessStoriesSlider from '@/layouts/SuccessStoriesSlider.vue'
import BrandSlider from '@/layouts/BrandSlider.vue'
import Blog from '@/layouts/Blog.vue'
import { DataAnalyticsIconStrip } from '@/content/Services.js'


export default {
  metaInfo() {
    return {
      title: 'Data Analytics',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
   components: { 
    PageHero,
    RoundedBottomTwo,
    Slider,
    Blog,
     BrandSlider,
     SuccessStoriesSlider
  },
  name: 'BigDataAnalytics',
  data () {
    return {
      pageTitle: 'Data Analytics',
      titleStatement: 'Securely integrate data analytics straight into your organizational DNA to remain innovative, relevant and competitive, all while in compliance with consumer privacy regulations.',
      solutions: [],
      iconStrip: [],
      services: [],
      numberOfSlides: {
        small: 1,
        medium: 3,
        large: 3,
        xlarge: 4
      },
    }
  },
  mounted () {
    this.setSolutionCardAnimation()
  },
  created () {
    this.solutions = DataSolutions
    this.services = Services
    this.iconStrip = DataAnalyticsIconStrip
  },
  methods: {
    setSolutionCardAnimation () {
      const positionOneDivs = document.getElementsByClassName("position-one");
      const positionTwoDivs = document.getElementsByClassName("position-two");
      
      for(let i = 0; i < positionOneDivs.length; i++) {
        positionOneDivs[i].onclick = () => {
          positionOneDivs[i].classList.remove('delay')
          positionOneDivs[i].classList.add('active')
          positionTwoDivs[i].classList.add('active')
          positionTwoDivs[i].classList.add('delay')
        }
      }

      for(let i = 0; i < positionTwoDivs.length; i++) {
        positionTwoDivs[i].onclick = () => {
          positionTwoDivs[i].classList.remove('active')
          positionTwoDivs[i].classList.remove('delay')
          positionOneDivs[i].classList.remove('active')
          positionOneDivs[i].classList.add('delay')
        }
      }
    }
  }
}
</script>

<style>

.icon-strip {
    padding: 50px 0;
    background-color: $lightColor;

    ul {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
      margin: 0;
      list-style: none;
      justify-content: space-around;

      li {
        margin: 0 1%;
        max-width: 15%;

        .icon-title {
          padding-top: 15px;
          font: normal normal 400 16px/16px $primaryFont;

          @include small {
            display: none;
          }
        }

        .icon-wrap {
          img {
            width: 90px;
            padding: 20px;
            filter: hue-rotate(306deg);
            background-color: white;
            border: 1px solid $primaryColor;
            border-radius: 50%;

            @include small {
              padding: 10px;
            }
          }
        }
      }
    }
  }
</style>