
import { createRouter,createWebHistory  } from 'vue-router';
import Home from "./pages/Home.vue";
import About from "./pages/About.vue";
import Blogs from "./pages/Blogs.vue";
import Careers from "./pages/Careers.vue";
import CareerSingle from "./pages/CareerSingle.vue";
import Services from "./pages/Services.vue";
import Work from "./pages/Work.vue";
import Contact from "./pages/Contact.vue";
import CloudSolutions from "./pages/CloudSolutions.vue";
import BigDataAnalytics from "./pages/BigDataAnalytics.vue";
import CaseStudy from "./pages/CaseStudy.vue";
import CompanyEvent from "./pages/CompanyEvent.vue";
import ThankYou from "./pages/ThankYou.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
  },
  {
    path: "/careers/:id",
    name: 'CareerSingle',
    component: CareerSingle,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/work",
    name: "Work",
    component: Work,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/services/cloud-solutions",
    name: "Cloud_Solutions",
    component: CloudSolutions,
  },
  {
    path: "/services/bigdata-analytics",
    name: "Big_Data_Analytics",
    component: BigDataAnalytics,
  },
  {
    path: "/casestudy",
    name: "casestudy",
    component: CaseStudy,
  },
  {
    path: "/nov2022event",
    name: "CompanyEvent",
    component: CompanyEvent,
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    component: ThankYou,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});
trackRouter(router);

router.beforeEach((to, from, next) => {
  // Jump to top of page when link is clicked
  window.scrollTo(0, 0);
  next()
});

export default router;