export const CaseStudies = [
  {
    id: 'casestudy-1',
    introduction: `BrokerBay is your brokerage’s new command centre. Their lightning fast cloud ecosystem will drastically reduce your administrator workloads, 
      empower your agents to do more deals, and thoroughly impress clients, all from one central location.`,
    industry: 'Other',
    projectLocation: 'Canada',
    content: 'Badal played a pivotal role in architecting a robust, scalable, and cloud native solution for BrokerBay. Badal assisted with app/infrastructure modernization, establishing a DevOps culture focused around GCP.',
    testimonial: 'Badal is a Google Partner that every startup wishes they had. They were able to guide us through the most complicated of transitions with no downtime.',
    testimonialAuthor: 'Pavel Choulguine, CTO, BrokerBay Inc.',
    challenge: `BrokerBay’s success in growing their real-estate platform inevitably led to resiliency and scalability issues. While their in-house engineering team was focused on core product features, 
      they required expertise to scale their infrastructure and improve their DevOps practices.`,
    solution: `Badal helped migrate BrokeryBay to a cloud native solution, which leveraged GKE, CloudSQL, Pub Sub and Cloud Monitoring suite (trace/logging/monitoring debug). 
      In addition, Badal implemented a GitFlow process, enabling blue green deploys and empowering application teams to increase the visibility and velocity of releases.`,
    results: `BrokerBay’s migration to a microservice-based architecture increased the velocity of software releases and reduced service downtime. 
      Their integration with GCP cloud monitoring provided application insight and proactive monitoring.`
  },
  {
    id: 'casestudy-2',
    introduction: `Setter Inc. provides home maintenance and repair services. The Company offers advisory, windows cleaning, landscaping, furnace installation, and countertop replacement services. Setter serves customers in Canada.`,
    industry: 'Other',
    projectLocation: 'Canada',
    content: 'Badal was engaged by Setter to help architect a resilient and scalable infrastructure and reduce their time-to-market for product features.',
    testimonial: 'Badal helped us establish a DevOps practice by introducing containerization and GKE to our workflow. Allowed the dev team to focus on mission at hand will ensuring a secure and resilient infrastructure in the backend.',
    testimonialAuthor: 'Ambles Kwok, CTO, Setter Inc.',
    challenge: `As Setter experienced a hyper growth stage, they recognized that scaling would be a challenge as applications were tightly coupled to their infrastructure. The infrastructure that they were running was not optimized to take advantage of cloud native features.`,
    solution: `Badal migrated all services to GKE and created CI/CD pipelines that leveraged blue/green deploys. In addition, Badal implemented logging and metric standards across all services to increase operational visibility and automation using the cloud monitoring suite.`,
    results: `GKE adoption gave the engineering team a more consistent and reproducible pattern for development to production. Also, added an easy to use framework that leveraged GKE features and enabled HA across all micro services (application level and infrastructure level).
    Integrated with cloud native solutions, such as CloudSQL, which dramatically decreased operational overhead.`
  },
  {
    id: 'casestudy-3',
    introduction: `The Bank of Nova Scotia, operating as Scotiabank, is a Canadian multinational banking and financial services company headquartered in Toronto, Ontario. One of Canada's Big Five banks, it is the third largest Canadian bank by deposits and market capitalization.`,
    industry: 'Financial Services',
    projectLocation: 'Canada',
    testimonial: 'Saved Bank annual licensing cost on proprietary software, while also allowing to fully utilize cloud native capabilities in a cost effective and efficient manner.',
    // testimonialAuthor: 'Pavel Choulguine, CTO, BrokerBay Inc.',
    challenge: `Scotiabank used a legacy grid computing scheduler on-premise to distribute large scale calculations for risk modeling. The on-premise grid ran on a shared set of compute resources whose capacity was eventually outpaced by the internal demand for the service; causing growing delays for critical workloads downstream.`,
    solution: `Badal sought to solve the resource contention problem by implementing a distributed computing framework that could be scaled for each team independent from one another. This framework consisted of implementing an API to replace the legacy scheduling software on top of GCP services. Badal also helped teams navigate regulatory compliance throughout the migration process.`,
    results: `Scotiabank’s migration eliminated missed deadlines caused by resource constraints and provided a savings of $500k/yr with the replacement of the proprietary scheduling software. Operations have been drastically simplified with the use of GCP native interfaces for logging and monitoring. Operational costs are kept low with the solution’s built-in resiliency and use of preemptible VMs.`
  }
]