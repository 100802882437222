export const Solutions = [
  {
    title: 'Cloud Migrations',
    summary: 'Streamline your transition, while minimizing stress and costs.',
    details: `<p>Allow our team of specialists to lead you through each phase of your transition to the cloud, guaranteeing a seamless and protected shift that unleashes the complete capabilities of cloud technology for your enterprise.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Strategic Planning and Assessment<br> - Secure and Efficient Migration<br> - Cloud Resource Optimization<br> - Cloud Cost Management<br> - Performance Monitoring and Scaling</p>`
  },
  {
    title: 'Big Data Analytics and ML',
    summary: 'Make sense of your business data and derive deeper, more-impactful insights.',
    details: `<p>Big Data refers to datasets that are difficult to process using traditional methods due to size, speed or complexity. A strong foundation is essential for any modern enterprise that wants to make data-driven decisions that create long-term success.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Streaming Analytics<br>- ML Model Training and Hosting<br>- Business Intelligence and Reporting<br>- Data-lake/Hadoop Migration<br>- Cloud-Native Warehousing<br>- Marketing Analytics</p>`
  },
  {
    title: 'Devops and Transformation',
    summary: 'Empower your developers to focus on what truly matters through effective pipeline management.',
    details: `<p>DevOps can help your team eliminate cumbersome, manual processes and shorten the system development lifecycle. We provide the tools your team needs to release software at high velocity, without sacrificing security or quality.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Infrastructure Modernization and Automation<br>- Continuous Integration/Deployments<br>- Application Containerization<br>- Advanced Container Orchestration and Networking<br> - Serverless Frameworks<br>- API Management and Security</p>`
  },
  {
    title: 'Security and Governance',
    summary: 'Experience organizational peace-of-mind with best-in-class security foundations for the Cloud.',
    details: `<p>It doesn’t matter how elegant your Cloud solution is—if it’s not secure, it’s not a solution. From day one, we work to help our clients better understand their security needs and create a more-secure Cloud.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Data Access control patterns<br> - Access Monitoring<br> - Encryption<br> - Data Residency<br> - Governance and Compliance<br></p>`
  },
];

export const Services = [
  {
    icon: 'A',
    title: 'Plan',
    description: 'We work directly with your engineers and architects to ensure you have everything you need to get the most out of your Cloud.'
  },
  {
    icon: 'B',
    title: 'Deploy',
    description: 'Through direct collaboration, we help you implement the core foundations to support your applications and processes.'
  },
  {
    icon: 'C',
    title: 'Automate',
    description: 'Our tailor-built pipelines let you automate how you build, package, and test applications.'
  },
  {
    icon: 'D',
    title: 'Secure',
    description: 'From day one, we establish a foundation of security designed to keep you safe without slowing down workflow.'
  },
  {
    icon: 'E',
    title: 'Management',
    description: 'Our certified engineers provide support to ensure your applications are secured and running 24/7.'
  }, 
  {
    icon: 'F',
    title: 'Enterprise',
    description: 'Our team provides enterprise-level security for data analytics, while helping you take advantage of cloud-native solutions.'
  },
];

export const Processes = [
  {
    title: 'Plan',
    description: 'We work directly with your engineers and architects to ensure you have everything you need to get the most out of your Cloud.'
  },
  {
    title: 'Deploy',
    description: 'Through direct collaboration, we help you implement the core foundations to support your applications and processes.'
  },
  {
    title: 'Automate',
    description: 'Our tailor-built pipelines let you automate how you build, package, and test applications.'
  },
  {
    title: 'Secure',
    description: 'From day one, we establish a foundation of security designed to keep you safe without slowing down workflow.'
  },
];


export const CloudSolutions = [
  {
    title: 'Cloud Foundation',
    summary: 'Building a practice that will help you utilize cloud in a secure & compliant manner.',
    details: `<p>Maintaining consistency as your cloud practices grows is critical and a challenging focus for many enterprises. We help enterprise clients build an operational model for using GCP that is focused on security, automation and compliance.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Deployment automation<br>- Tooling Adoption<br>- Change Management Process<br>- Training</p>`
  },
  {
    title: 'Migrations',
    summary: 'Streamline your transition, while minimizing stress and costs.',
    details: `<p>Allow our team of specialists to lead you through each phase of your transition to the cloud, guaranteeing a seamless and protected shift that unleashes the complete capabilities of cloud technology for your enterprise.</p> 
    <h3>Our team can help with:</h3>
    <p class='list'>- Strategic Planning and Assessment<br> - Secure and Efficient Migration<br> - Cloud Resource Optimization<br> - Cloud Cost Management<br> - Performance Monitoring and Scaling</p>`
  },
  {
    title: 'Devops and SecOps',
    summary: 'Empower your developers to focus on what truly matters through effective pipeline management.',
    details: `<p>DevOps can help your team eliminate cumbersome, manual processes and shorten the system development lifecycle. We provide the tools your team needs to release software at high velocity, without sacrificing security or quality.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Infrastructure Modernization and Automation<br>- Continuous Integration/Deployments<br>- Application Containerization<br>- Advanced Container Orchestration and Networking<br> - Serverless Frameworks<br>- API Management and Security</p>`
  },
  {
    title: 'Security and Governance',
    summary: 'Experience organizational peace-of-mind with best-in-class security foundations for the Cloud.',
    details: `<p>It doesn’t matter how elegant your Cloud solution is—if it’s not secure, it’s not a solution. From day one, we work to help our clients better understand their security needs and create a more-secure Cloud.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Data Access control patterns<br> - Access Monitoring<br> - Encryption<br> - Data Residency<br> - Governance and Compliance<br></p>`
  },
];

export const IconStrip = [
  {
    logo: 'compute_engine',
    title: 'Compute Engine',
  },
  {
    logo: 'virtual_private_cloud',
    title: 'Virtual Private Cloud',
  },
  {
    logo: 'cloud_load_balancing',
    title: 'Cloud Load Balancing',
  },
  {
    logo: 'cloud_sql',
    title: 'Cloud SQL',
  },
  {
    logo: 'error_reporting',
    title: 'Error Reporting',
  },
  {
    logo: 'trace',
    title: 'Trace',
  }
];

export const DataAnalyticsIconStrip = [
  {
    logo: 'bigquery',
    title: 'Big Query',
  },
  {
    logo: 'looker',
    title: 'Looker',
  },
  {
    logo: 'pubsub',
    title: 'Pub/Sub',
  },
  {
    logo: 'dataflow',
    title: 'Dataflow',
  },
  {
    logo: 'cloud_composer',
    title: 'Cloud Composer',
  }
];

export const DataSolutions = [
  {
    title: 'DATA FOUNDATION & DATA LAKES',
    summary: 'Harness the power of structured and unstructured data for unparalleled insights',
    details: `<p>Consolidate, integrate, and transform your data into a reliable source for reporting and business intelligence with our robust Data Foundation solutions. Dive into the realm of raw and diverse data types, exploring, analyzing, and driving innovation with our agile Data Lakes.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Data ingestion & integration <br>- Data storage<br>- Data mesh & data marts<br>- Scalability & flexibility<br></p>`
  },
  {
    title: 'DATA MANAGEMENT ',
    summary: 'Trust your data with embedded best practices at all stages of the data life cycle.',
    details: `<p>We offer excellence in organizing, storing, protecting, and leveraging data to deliver optimal insights for your business. </p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Data quality<br>- Data modeling<br>- Data lineage<br>- Schema flexibility <br>- Data security & governance<br></p>`
  },
  {
    title: 'DATA ANALYTICS',
    summary: 'Gain insights and drive growth.',
    details: `<p>We help you uncover your organization’s competitive edge using your data. Whether it is optimizing processes, enhancing customer experiences, or identifying new market opportunities, we empower you to drive data-driven decision-making and innovation.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- Predictive analytics<br>- Machine learning & artificial intelligence<br>- Real-time analytics<br>- Data monetization<br></p>`
  },
  {
    title: 'BUSINESS INTELLIGENCE ',
    summary: 'Democratize your organization’s data and make data-driven decisions.',
    details: `<p>We are a trusted Looker partner with deep knowledge and expertise in designing and deploying dashboards tailored to your organization’s business needs.</p> 
      <h3>Our team can help with:</h3>
      <p class='list'>- KPI definition and monitoring<br> - Alerting and notifications<br> - Data visualization<br> - Embedded analytics<br> - Data source connectivity<br>- External client dashboards</p>`
  },
];