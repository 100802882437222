<template>
  <div id="home-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement"
      :hideBreadCrumbs="true">
    </page-hero>

    <div class="home-hero-swirl">
      <div class="swirl"></div>
    </div>

    <brand-slider></brand-slider>

    <div class="how-we-help-container">
      <div class="uk-container">
        <div class="content-wrapper">
          <div class="image-container">
            <img src='@/assets/pages/home/how-we-make-the-cloud.png' alt="Heres how we help" title="Heres how we help">
          </div>

          <div class="content-container">
            <h2>How We Make The Cloud Work For You</h2>

            <p>The Cloud is an essential tool for modern enterprises. But without the right team in your corner, it can easily become just another organizational headache. 
              Our experts can help you get the most from your technology—from Cloud Migration to Big Data Analytics and more.</p>

            <primary-button 
              route="/services" 
              text="Learn more">
            </primary-button>
          </div>
        </div>
      </div>

      <div class="cloud6-process">
        <div class="text-block">
          Our Cloud6 Process
        </div>

        <div class="uk-container">
          <div class="cloud6-process-items flexbox">
            <div class="item" 
              v-for="process of cloud6Processes" 
              :key="process.title">

              <div class="line"></div>
              <div class="icon">
                {{ process.icon }}
              </div>
              <div class="process">
                {{ process.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <rounded-bottom-one></rounded-bottom-one>

    <!-- <bios></bios> -->

    <partner-slider></partner-slider>

    <partners-bottom-swirl></partners-bottom-swirl>

    <testimonial-slider></testimonial-slider>

    <blog></blog>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
// import Bios from '@/layouts/Bios.vue'
import BrandSlider from '@/layouts/BrandSlider.vue'
import PartnerSlider from '@/layouts/PartnerSlider.vue'
import Blog from '@/layouts/Blog.vue'
import TestimonialSlider from '@/layouts/TestimonialSlider.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import RoundedBottomOne from '@/components/RoundedBottomOne.vue'
import PartnersBottomSwirl from '@/components/PartnersBottomSwirl.vue'

export default {
  metaInfo() {
    return {
      title: 'Home',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
  components: { 
    PageHero,
    // Bios,
    BrandSlider,
    Blog,
    PartnerSlider,
    TestimonialSlider,
    PrimaryButton,
    RoundedBottomOne,
    PartnersBottomSwirl
  },
  name: 'Home',
  data () {
    return {
      pageTitle: 'Elevating business to a higher Cloud',
      titleStatement: 'We are your Cloud experts.',
      cloud6Processes: [
        {
          title: 'Plan',
          icon: 'A'
        },
        {
          title: 'Deploy',
          icon: 'B'
        },
        {
          title: 'Automate',
          icon: 'C'
        },
        {
          title: 'Secure',
          icon: 'D'
        },
        {
          title: 'Manage',
          icon: 'E'
        },
        {
          title: 'Enterprise',
          icon: 'F'
        }
      ]
    }
  },
}
</script>