<template>
  <div class="uk-container social-sidebar-container">
    <div class="social-sidebar">
      <div class="social-link-container" v-if="$facebookURL">
        <a :href="$facebookURL" target="_blank" class="fb social-link" title="Facebook">
          <span>Facebook</span>
        </a>
      </div>
      <div class="social-link-container" v-if="$youtubeURL">
        <a :href="$youtubeURL" target="_blank" class="yt social-link" title="Youtube">
          <span>Youtube</span>
        </a>
      </div>
      <div class="social-link-container" v-if="$linkedInURL">
        <a :href="$linkedInURL" target="_blank" class="in social-link" title="LinkedIn">
          <span>linkedIn</span>
        </a>
      </div>
      <div class="social-link-container" v-if="$twitterURL">
        <a :href="$twitterURL" target="_blank" class="tw social-link" title="Twitter">
          <span>Twitter</span>
        </a>
      </div>
    </div>
  </div>
</template>